import { sitePhrasing } from "../../../../Language/languageHandler";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import SelectFieldInput from "../../../FormFields/SelectInputField/SelectInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import { annualReportDisclaimer1, annualReportDisclaimer2, annualReportTypeOfBusiness, blankRegisteredAgentFn, iAgreeText, registeredAgentFields, termAndConditionAcceptText, yesOrNoValues } from "../PurchaseConstants";
import { forcedRegistered } from "../PurchaseFormHelpers";

export default function GeorgiaAnnualReportFields(props: {
    errors: any;
    touched: any;
    values: any;
    setFieldValue: (field: string, value: any) => void;
}) {
    const { errors, touched, values, setFieldValue } = props;

    return (
        <>
            <RadioInputField
                fieldName={"annualReport.typeOfEntity"}
                fieldLabel={"What type of entity is your company?"}
                errors={errors}
                required={true}
                touched={touched}
                options={annualReportTypeOfBusiness}
            />
            {values.annualReport.typeOfEntity === "Corp, INC, other" && (
                <>
                    <h3>Please provide the following information:</h3>
                    <TextInputField
                        fieldName={"annualReport.entityCEO"}
                        fieldLabel={"CEO of your company"}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={"CEO of your company"}
                    />
                    {/* annualReport.entityCEOAddress */}
                    <AddressInputFieldGroup
                        fieldName={"annualReport.entityCEOAddress"}
                        errors={errors}
                        touched={touched}
                    />
                    <br />
                    <TextInputField
                        fieldName={"annualReport.entityCFO"}
                        fieldLabel={"CFO of your company"}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={"CFO of your company"}
                    />
                    {/* annualReport.entityCFOAddress */}
                    <AddressInputFieldGroup
                        fieldName={"annualReport.entityCFOAddress"}
                        errors={errors}
                        touched={touched}
                    />
                    <br />

                    <TextInputField
                        fieldName={"annualReport.entitySecretary"}
                        fieldLabel={"Secretary of your company"}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={"Secretary of your company"}
                    />
                    {/* annualReport.entitySecretaryAddress */}
                    <AddressInputFieldGroup
                        fieldName={"annualReport.entitySecretaryAddress"}
                        errors={errors}
                        touched={touched}
                    />
                </>
            )}

            <h3>Do you need to change any of the following information?</h3>
            <RadioInputField
                fieldName={"annualReport.informationChange.principalAddress.changed"}
                fieldLabel={"Principal Address?"}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />
            {values.annualReport.informationChange.principalAddress.changed === "yes" && (
                <AddressInputFieldGroup
                    fieldName={
                        "annualReport.informationChange.principalAddress.newAddress"
                    }
                    errors={errors}
                    touched={touched}
                // fieldMessages={{
                //   state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``
                // }}
                // disableFields={{
                //   state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
                // }}
                />
            )}

            <RadioInputField
                fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
                fieldLabel={"Registered Agent?"}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />
            {values.annualReport.informationChange.registeredAgent.agentChange === "yes" && (
                <TextInputField
                    fieldName={"annualReport.informationChange.registeredAgent.newAgent"}
                    fieldLabel={"Registered Agent Name"}
                    errors={errors}
                    touched={touched}
                    required={true}
                    placeholder={"Registered Agent Name"}
                />
            )}

            <RadioInputField
                fieldName={
                    "annualReport.informationChange.registeredAgent.agentAddressChange"
                }
                fieldLabel={"Registered Agent Address?"}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />
            {values.annualReport.informationChange.registeredAgent.agentAddressChange === "yes" && (
                <AddressInputFieldGroup
                    fieldName={
                        "annualReport.informationChange.registeredAgent.newAddress"
                    }
                    errors={errors}
                    touched={touched}
                    fieldMessages={{
                        state: forcedRegistered[sitePhrasing.state]
                            ? `Your registered agent must be located in ${sitePhrasing.state}.`
                            : ``,
                    }}
                    disableFields={{
                        state: forcedRegistered[sitePhrasing.state] ? true : false,
                    }}
                    showCounty={true}
                />
            )}

            <RadioInputField
                fieldName={
                    "annualReport.informationChange.registeredAgent.emailAddressChange"
                }
                fieldLabel={"Registered Agent E-Mail address?"}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />
            {values.annualReport.informationChange.registeredAgent.emailAddressChange === "yes" && (
                <TextInputField
                    fieldName={
                        "annualReport.informationChange.registeredAgent.newEmailAddress"
                    }
                    fieldLabel={"Enter Registered Agent's new COMPLETE e-mail address"}
                    errors={errors}
                    touched={touched}
                    required={true}
                    placeholder={"Enter Registered Agent's new COMPLETE e-mail address"}
                />
            )}

            <RadioInputField
                fieldName={
                    "annualReport.informationChange.emailAddress.emailAddressChange"
                }
                fieldLabel={"Principal Business E-Mail address?"}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
            />
            {values.annualReport.informationChange.emailAddress.emailAddressChange === "yes" && (
                <TextInputField
                    fieldName={
                        "annualReport.informationChange.emailAddress.newEmailAddress"
                    }
                    fieldLabel={"Enter your Principal Business new COMPLETE e-mail address"}
                    errors={errors}
                    touched={touched}
                    required={true}
                    placeholder={"Enter your Principal Business new COMPLETE e-mail address"}
                />
            )}

            <CheckboxInputField
                fieldName={"annualReport.annualAgree"}
                fieldLabel={annualReportDisclaimer1}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={'iAgree'}
                fieldLabel={iAgreeText}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={'termAndConditionAgree'}
                fieldLabel={<span dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }} />}
                touched={touched}
                errors={errors}
            />


            <TextInputField
                fieldName={"annualReport.electronicSignature"}
                fieldLabel={"Name of Individual Signing - Electronic Signature"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Electronic Signature"}
                customClass={"signature-font"}
            />

            <SelectFieldInput
                fieldName={"annualReport.title"}
                fieldLabel={"Title"}
                errors={errors}
                touched={touched}
                required={true}
                disabled={!values.annualReport.typeOfEntity ? true : false}
                placeholder={"- Select Title -"}
                options={values?.annualReport?.typeOfEntity === "Corp, INC, other" ? GeorgiaAnnualCorporationTitleValues : GeorgiaAnnualTitleValues}
            />

            <CheckboxInputField
                fieldName={"annualReport.iAmAuthorized"}
                fieldLabel={annualReportDisclaimer2}
                touched={touched}
                errors={errors}
            />
        </>
    )
}

const GeorgiaAnnualTitleValues = [
    { value: "Organizer", label: "Organizer" },
    { value: "Member", label: "Member" },
    { value: "Manager", label: "Manager" },
    { value: "Attorney In Fact", label: "Attorney In Fact" },
    { value: "Court-Appointed Fiduciary", label: "Court-Appointed Fiduciary" },
    { value: "Registered Agent", label: "Registered Agent" },
    { value: "Authorized Person", label: "Authorized Person" },
];

const GeorgiaAnnualCorporationTitleValues = [
    { value: "Officer", label: "Officer" },
    { value: "Chairperson of the Board of Directors", label: "Chairperson of the Board of Directors" },
    { value: "Attorney In Fact", label: "Attorney In Fact" },
    { value: "Incorporator", label: "Incorporator" },
    { value: "Court-Appointed Fiduciary", label: "Court-Appointed Fiduciary" },
    { value: "Director", label: "Director" },
    { value: "Registered Agent", label: "Registered Agent" },
    { value: "Authorized Person", label: "Authorized Person" },
];
