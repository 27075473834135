import { Form, Formik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import { sitePhrasing } from "../../../Language/languageHandler";
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField";
import TextInputField from "../../FormFields/TextInputField/TextInputField";
import { AnnualReportFields } from "./AnnualReportFields";
import { EinTaxFields } from "./EinTaxFields";
import { LegalNoticeAdvertisementFields } from "./LegalNoticeAdvertisementFields";
import { generalAgreementText, iAgreeText, termAndConditionAcceptText } from "./PurchaseConstants";
import { generatePurchaseFormInitialValues, generatePurchaseValidationSchema } from "./PurchaseFormHelpers";
import { FictitiousNameRenewalFields } from "./FictitiousNameRenewalFields";
import { ContactInfoFields } from "./ContactInfoFields";
import { promoCodes } from "../../../Redux/Shopping/shoppingHelpers";
import { BOIReportFields } from "./BOIReport";
import { AnnualReferenceNumberInstructions } from "./SharedPurchaseFieldComponents";

export default function PurchaseForm(props: any) {
  const {
    onSubmit,
    selectedProducts,
    disableForm,
    initialFormOptions = {},
    applyPromoCode,
    discountId,
    discountAmount,
  } = props;

  const [validDiscount, setValidDiscount] = useState(true);

  const validationSchemaFunction = useMemo(() => {
    const vFn = generatePurchaseValidationSchema(selectedProducts);
    return vFn;
  }, [selectedProducts]);
  const formikRef: any = useRef(null);
  useEffect(() => {
    if (formikRef && formikRef.current && formikRef.current.validateForm) {
      formikRef.current.validateForm();
    }
  }, [selectedProducts]);

  const scrollToError = () => {
    const silentErrors = document.querySelectorAll(".silent-error");
    if (silentErrors && silentErrors.length > 0) {
      silentErrors.forEach((x: any) => {
        if (x.focus && x.blur) {
          x.focus();
          x.blur();
        }
      });
    }

    const el: any = document.querySelector(".error");
    if (el) {
      (el?.parentElement ?? el)?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    const sel: any = document.querySelector(".silent-error");
    if (sel) {
      if (sel.focus && sel.blur) {
        sel.focus();
        sel.blur();
        sel.focus();
      }
      (sel?.parentElement ?? sel)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  let useGeneralAgreementText = false;

  if (
    (selectedProducts["laborLawPoster"] && Object.keys(selectedProducts).length === 1) ||
    (selectedProducts["certificateOfStatus"] && Object.keys(selectedProducts).length === 1) ||
    (selectedProducts["laborLawPoster"] && selectedProducts["certificateOfStatus"] && Object.keys(selectedProducts).length === 2)
  ) {
    useGeneralAgreementText = true;
  }

  const annualSelected =
    selectedProducts["annualReport"] ||
    selectedProducts["amend"] ||
    selectedProducts["reinstate"];


  const handlePromoCode = (promoCodeValue: string) => {
    if (promoCodeValue && promoCodes[promoCodeValue]) {
      setValidDiscount(true)
      applyPromoCode(promoCodeValue)
      return
    }
    setValidDiscount(false)
  }


  return (
    <>
      <div className="purchase-form-container">
        <Formik
          innerRef={formikRef}
          initialValues={generatePurchaseFormInitialValues(
            selectedProducts,
            initialFormOptions
          )}
          validationSchema={validationSchemaFunction}
          onSubmit={async (values, { setSubmitting }) => {
            // console.log("values",values);

            setSubmitting(false);
            onSubmit(values);
          }}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            // setValues,
          }) => (
            <div className="purchase-form">
              <Form>
                {/* {console.log("ERRROR", errors)} */}
                {!selectedProducts["fictitiousNameRenewal"] &&
                  !selectedProducts["einTaxId"] &&
                  !(annualSelected && sitePhrasing.state === "Tennessee") &&
                  !(annualSelected && sitePhrasing.state === "Florida") && (
                    <ContactInfoFields
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}

                {!selectedProducts["fictitiousNameRenewal"] &&
                  !(annualSelected && sitePhrasing.state === "Florida") && (
                    <TextInputField
                      mask={sitePhrasing.referenceMask}
                      fieldName={"referenceNumber"}
                      fieldLabel={"Reference Number"}
                      fieldSubLabel={annualSelected ? undefined : "(This number can be found on the top right hand corner of your letter)"}
                      customFieldInstructions={annualSelected ? () => (<AnnualReferenceNumberInstructions />) : undefined}
                      errors={errors}
                      touched={touched}
                      required={true}
                      placeholder={"Reference Number"}
                    />
                  )}

                {!selectedProducts["fictitiousNameRenewal"] &&
                  !selectedProducts["einTaxId"] &&
                  !selectedProducts["legalNoticeAdvertisement"] &&
                  !(annualSelected && sitePhrasing.state === "Florida") && (
                    <TextInputField
                      fieldName={"businessName"}
                      fieldLabel={"Complete Business Name"}
                      errors={errors}
                      touched={touched}
                      required={true}
                      placeholder={"Complete Business Name"}
                    />
                  )}

                {/* {sitePhrasing.state === "Tennessee" &&
                  selectedProducts["certificateOfStatus"] &&
                  !selectedProducts["fictitiousNameRenewal"] &&
                  !selectedProducts["einTaxId"] &&
                  !selectedProducts["legalNoticeAdvertisement"] &&
                  !annualSelected && (
                    <AddressInputFieldGroup
                      fieldName={"generalAddress"}
                      errors={errors}
                      touched={touched}
                    />
                  )} */}

                {selectedProducts["fictitiousNameRenewal"] && (
                  <FictitiousNameRenewalFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    selectedProducts={selectedProducts}
                  />
                )}

                {selectedProducts["legalNoticeAdvertisement"] &&
                  !selectedProducts["fictitiousNameRenewal"] && (
                    <LegalNoticeAdvertisementFields
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}

                {selectedProducts["boiReport"] && (
                  <BOIReportFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}

                {selectedProducts["einTaxId"] && (
                  <EinTaxFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}

                {(selectedProducts["annualReport"] ||
                  selectedProducts["amend"] ||
                  selectedProducts["reinstate"]) && (
                    <AnnualReportFields
                      values={values}
                      touched={touched}
                      errors={errors}
                      selectedProducts={selectedProducts}
                      setFieldValue={setFieldValue}
                    />
                  )}

                {useGeneralAgreementText && (
                  <>
                    <CheckboxInputField
                      fieldName={"generalAgree"}
                      fieldLabel={generalAgreementText}
                      touched={touched}
                      errors={errors}
                    />

                    <CheckboxInputField
                      fieldName={'iAgree'}
                      fieldLabel={iAgreeText}
                      touched={touched}
                      errors={errors}
                    />

                    <CheckboxInputField
                      fieldName={'termAndConditionAgree'}
                      fieldLabel={
                        <span
                          dangerouslySetInnerHTML={{
                            __html: termAndConditionAcceptText,
                          }}
                        />
                      }
                      touched={touched}
                      errors={errors}
                    />

                    <TextInputField
                      fieldName={"electronicSignature"}
                      fieldLabel={"Client Agreement & Signature"}
                      errors={errors}
                      touched={touched}
                      required={true}
                      placeholder={"Electronic Signature"}
                      customClass={"signature-font"}
                    />
                  </>
                )}

                {!discountId && promoCodes && Object.keys(promoCodes).length > 0 && (
                  <div className="promo-code-container">
                    <div className="promo-code-input">
                      <TextInputField
                        fieldName={"promoCode"}
                        fieldLabel={"Promo Code"}
                        errors={errors}
                        touched={touched}
                        placeholder={"Promo Code"}
                        disabled={isSubmitting || discountId}
                        extra={{
                          unstyled: true,
                        }}
                        onKeyDown={(e: any) => {
                          // console.log(e)
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handlePromoCode(values.promoCode)
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="step-btn btn promo-btn"
                        disabled={isSubmitting || !values.promoCode}
                        onClick={() => {
                          handlePromoCode(values.promoCode)
                        }}>
                        Check Code
                      </button>
                    </div>
                    {!validDiscount && (
                      <div className="promo-code-error">
                        <span style={{ color: 'red', fontStyle: 'italic' }}>
                          Invalid promo code
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {discountId && (
                  <div className="promo-code-container">
                    <div className="promo-code-applied">
                      <b>Promo Code:</b> {discountId} {discountAmount && discountAmount > 0 ? (
                        <span style={{
                          color: 'red',
                          // italics
                          fontStyle: 'italic'
                        }}>${discountAmount / 100} discount applied</span>
                      ) : null}
                    </div>
                  </div>
                )}

                <div className="final-purchase-form-section">
                  <div className="form-btn-container">
                    <button
                      type="submit"
                      className={`submit-btn btn ${disableForm ||
                        isSubmitting ||
                        Object.keys(errors).length > 0
                        ? "disabled-btn"
                        : ""
                        }`}
                      disabled={
                        disableForm ||
                        isSubmitting ||
                        Object.keys(errors).length > 0
                      }
                    >
                      Proceed to Checkout
                    </button>

                    {errors && Object.keys(errors).length > 0 && (
                      <div className="field-container error-btn-container">
                        <div
                          className="form-error form-error-btn"
                          onClick={scrollToError}
                        >
                          <b>
                            There are errors with the form. Click here to fix
                          </b>
                          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
}
