import { sitePhrasing } from "../../Language/languageHandler"
import { ShoppingState } from "./shoppingSlice"

export const productValues: any = {
  annualReport: {
    name: sitePhrasing.state === 'Florida' ? 'Annual Report' : `Annual Report & Certificate of ${sitePhrasing.certificateOf}`,
    // name: `Annual Report & Certificate of ${sitePhrasing.certificateOf}`,
    price: 29989,
    deleteProducts: ['einTaxId'],
    // deleteProducts: ['einTaxId', 'certificateOfStatus'],
    hideIfSelected: ['einTaxId'],
    customClass: '',
    // lateFee: 2500,
    // lateFeeName: 'Annual Report Late Fee',
    // lateFeeStart: '12/01/2024 10:00am',
    // lateFeeEnd: '12/02/2024 11:55pm',
  },
  einTaxId: {
    name: 'EIN / Tax ID',
    price: 19995,
    deleteProducts: ['annualReport'],
    hideIfSelected: ['annualReport'],
    customClass: '',
  },
  laborLawPoster: {
    name: 'Labor Law Poster',
    price: 12495,
    customClass: '',
  },
  certificateOfStatus: {
    name: `Certificate of ${sitePhrasing.certificateOf}`,
    price: 8995,
    // deleteProducts: ['annualReport'],
    // hideIfSelected: ['annualReport'],
    customClass: '',
    // hidden: true,
  },
}

export const promoCodes: {
  [key: string]: { id: 'string', amount: number }
} = {}

export const updateProductPrice = (productKey: string, price: number) => {
  productValues[productKey] = {
    ...productValues[productKey],
    price
  }
  // productValues[productKey].price = price
}


export const extraOfferings: any[] = []
// export const extraOfferings: any[] = [{
//   title: 'Annual Reports for Existing Customers',
//   offers: {
//     annualReport: {
//       name: 'Annual Report & Certificate of Status',
//       price: 29989,
//       linkTo: 'https://www.google.com',
//       customClass: '',
//     }
//   }
// }]

export const shippingOptions: any = {
  electronic: {
    id: 'electronic',
    name: 'Electronic via Email',
    subText1: 'No additional shipping charges & expedited Delivery!',
    subText2: 'Usually processed the next business day, but may take up to 3 business days.',
    amount: 0
  },
  physical: {
    id: 'physical',
    name: 'Physical via USPS Mail',
    subText1: 'Additional $8.25 shipping cost',
    subText2: 'May take up to 14 business days to receive by mail.',
    amount: 825
  }
}

export const customShippingOptions: any = {
  'legalNoticeAdvertisement': {
    electronic: {
      id: 'electronic',
      name: 'Electronic via Email',
      subText1: 'No additional shipping charges & expedited Delivery!',
      subText2: 'May take 1-2 weeks to be published, depending on the day and time the order is received. Once your ad is published, allow an additional 1-2 weeks to receive your copy and notarized affidavit.',
      amount: 0
    },
    physical: {
      id: 'physical',
      name: 'Physical via USPS Mail',
      subText1: 'Additional $8.25 shipping cost',
      subText2: 'May take 1-2 weeks to be published, depending on the day and time the order is received. Once your ad is published, allow an additional 2-3 weeks to receive your copy and notarized affidavit.',
      amount: 825
    }
  }
}

export const discountOffers = [
  {
    amount: 6495,
    selection: ['laborLawPoster', 'certificateOfStatus', 'annualReport']
  },
  {
    amount: 4000,
    selection: ['certificateOfStatus', 'annualReport']
  },
  {
    amount: 2495,
    selection: ['laborLawPoster', 'legalNoticeAdvertisement']
  },
  {
    amount: 2495,
    selection: ['laborLawPoster', 'annualReport']
  },
  {
    amount: 1496,
    selection: ['laborLawPoster', 'certificateOfStatus', 'einTaxId']
  },
  {
    amount: 1500,
    selection: ['laborLawPoster', 'certificateOfStatus']
  },
]

export const upsellDiscountOffers = [
  {
    amount: 5500,
    selection: ['laborLawPoster', 'legalNoticeAdvertisement']
  },
]

export const updateCartInfo = (cartState: ShoppingState, update: {
  shipping?: string,
  product?: string,
  updateProductInfo?: boolean,
  isUpsell?: boolean,
  promoCode?: string
}) => {
  const state = {
    ...cartState
  }

  const {
    shipping,
    product,
    updateProductInfo,
    isUpsell,
    promoCode
  } = update

  if (shipping && shippingOptions[shipping]) {
    state.shippingMethod = shippingOptions[shipping].id
    state.shippingTotal = shippingOptions[shipping].amount
  }

  // Add products to cart
  const updatedProducts = {
    ...state.selectedProducts,
  }

  if (product && productValues[product]) {
    const productInfo = productValues[product]
    const {
      name,
      lateFee,
      lateFeeName
    } = productInfo

    const lateFeeId = `${product}LateFee`
    const lateProduct = {
      name: lateFeeName ? lateFeeName : `${name} Late Fee`,
      price: lateFee,
    }

    if (updateProductInfo && updatedProducts[product]) {
      delete updatedProducts[product]
    }

    if (!updatedProducts[product]) {
      updatedProducts[product] = { ...productValues[product] }
      if (isUpsell) {
        updatedProducts[product].isUpsell = true
      }

      if (lateFee) {
        updatedProducts[lateFeeId] = lateProduct
      }

      if (productValues[product].deleteProducts) {
        productValues[product].deleteProducts.forEach((x: string) => {
          delete updatedProducts[x]
        })
      }
    } else {
      delete updatedProducts[product]
      if (lateFee) {
        delete updatedProducts[lateFeeId]
      }
    }
  }
  state.selectedProducts = {
    ...updatedProducts
  }


  if (promoCode && promoCodes[promoCode]) {
    console.log('promoCode', promoCode, promoCodes[promoCode])
    console.log(promoCodes[promoCode].id, promoCodes[promoCode].amount)
    state.discountId = promoCodes[promoCode].id
    state.discount = promoCodes[promoCode].amount
    console.log(state)
  } else {
    state.discountId = ''
    state.discount = 0
  }

  // check for discounts
  if (!state.prepaid && !state.discountId) {
    // console.log('checking for discounts', state.discountId, state)
    let productSelectedIsPartOfUpsell = false
    if (state.step === 3 && product) {
      productSelectedIsPartOfUpsell = upsellDiscountOffers.some((d) => {
        return d.selection.includes(product)
      })
    }
    const validDiscounts = ([...(productSelectedIsPartOfUpsell ? upsellDiscountOffers : []), ...discountOffers]).filter((d) => {
      let valid = true
      d.selection.forEach((s) => {
        if (!state.selectedProducts[s]) {
          valid = false
        }
      })
      return valid
    })
    if (validDiscounts.length > 0) {
      state.discount = validDiscounts[0].amount
    } else {
      state.discount = 0
    }
  }

  // update cart total
  let total = 0
  if (state.selectedProducts) {
    Object.keys(state.selectedProducts).forEach((p) => {
      total += state.selectedProducts[p].price
    });
    // total -= state.discount ? state.discount : 0
    state.cartTotal = total
  } else {
    state.cartTotal = 0
  }

  // check if discount is greater than cart total
  if (state.discount > state.cartTotal) {
    state.discount = 0
  }

  return state
}


export type SpecialPricingType = {
  [state: string]: {
    [product: string]: {
      sectionTitle: string,
      productKey: string,
      values: {
        [product: string]: {
          name: string,
          subText?: string,
          price: number
        }
      }
    }
  }
}


export const specialPricingRequired: SpecialPricingType = {
  'Mississippi': {
    'annualReport': {
      sectionTitle: 'Select your business entity structure:',
      productKey: 'annualReport',
      values: {
        'domesticLLC': {
          name: 'Domestic LLC',
          subText: 'Operating in the state of MS',
          price: 29989
        },
        'corporation': {
          name: 'Corporation',
          // subText: '',
          price: 29989
        },
        'nonProfit': {
          name: 'Non-Profit',
          // subText: '',
          price: 29989
        },
        'foreignLLC': {
          name: 'Foreign LLC',
          subText: 'Operating outside the state of MS',
          price: 49989
        },
        'other': {
          name: 'Other',
          // subText: '',
          price: 29989
        }
      }
    }
  },
  'Virginia': {
    'annualReport': {
      sectionTitle: 'Select your business entity structure:',
      productKey: 'annualReport',
      values: {
        'llc': {
          name: 'LLC',
          // subText: '',
          price: 29989
        },
        'stockCorporation': {
          name: 'Stock Corporation',
          subText: `For-profit corporation, formed to generate a profit for shareholders.`,
          price: 39989
        },
        'nonStockCorporation': {
          name: 'Non-Stock Corporation',
          subText: `Non-profit corporation, organized for not-for-profit purposes, such as a tax-exempt, charitable organization or a property owners' association.`,
          price: 39989
        },
      }
    }
  }
}