import { ErrorMessage } from "formik";
import { ErrorComponent } from "../../Error/ErrorComponent";
import { checkVal } from "../helperFunctions";
// import { useState } from "react";

export default function CheckboxInputFieldArrayGroup(props: {
  fieldLabel: React.ReactNode,
  fieldName: string,
  errors: any,
  touched: any,
  customClass?: string,
  options: { value: string | number, label?: string }[],
  value: any[],
  // setFieldValue: (f: string, v: any[]) => void,
  onChange?: (e: any) => void,
  required?: boolean
}) {
  const {
    fieldLabel,
    fieldName,
    errors,
    touched,
    customClass,
    value = [],
    // setFieldValue,
    required = false,
    onChange = () => { }
  } = props

  const hasError = checkVal(errors, fieldName)

  // const [hasTouched, setHasTouched] = useState(false)

  const selectedKeys: any = {}

  value.forEach((val: any) => {
    selectedKeys[val] = true
  })

  return (
    <div className={`field-container ${customClass ? customClass : ''}`}>
      <label htmlFor={fieldName} className={"check-box-group-label"}>
        {fieldLabel}{required ? '*' : ''}
      </label>
      <br />
      <div className="check-box-array-group">
        {props.options.map((option, idx) => (
          <label key={idx} className={"check-box"}>
            <input
              type="checkbox"
              value={option.value}
              checked={selectedKeys[option.value] ? true : false}
              onChange={(e) => {
                // if(!hasTouched) {
                //   setHasTouched(true)
                // }
                if (e.target.checked) {
                  onChange([...value, option.value])
                } else {
                  onChange(value.filter((v) => v !== option.value))
                }
              }}
            />
            {option.label ? option.label : option.value}
          </label>
        ))}
      </div>


      <br />
      <ErrorMessage name={fieldName} render={ErrorComponent} />

      {hasError && (
        <div className="form-error">
          {hasError}
        </div>
      )}
    </div>
  )
}
