import { sitePhrasing } from "../../../../Language/languageHandler";
import { updateProductPrice } from "../../../../Redux/Shopping/shoppingHelpers";
import {
    updateCart,
    updateProductCartInfo,
} from "../../../../Redux/Shopping/shoppingSlice";
import { useAppDispatch } from "../../../../Redux/store";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import {
    ArrayEntryFieldType,
    ArrayFieldInput,
} from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import SelectFieldInput from "../../../FormFields/SelectInputField/SelectInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import ImportantMessage from "../../../ImportantMessage/ImportantMessage";
import { iAgreeText, termAndConditionAcceptText, yesOrNoValues } from "../PurchaseConstants";
import { forcedRegistered, forcedStatePrincipal } from "../PurchaseFormHelpers";

export default function VirginiaAnnualReportFields(props: {
    errors: any;
    touched: any;
    values: any;
    selectedProducts: any;
    setFieldValue: any;
}) {
    const { errors, touched, values, selectedProducts, setFieldValue } = props;

    const dispatch = useAppDispatch();

    const updateShoppingCart = (product: string) => {
        dispatch(updateCart(product));
    };

    const updateCartInfo = (product: string) => {
        dispatch(updateProductCartInfo(product));
    };

    return (
        <>

            <h3>Do you need to change any of the following information?</h3>

            {values.annualReport.typeOfEntity === "llc" && (
                <>
                    <RadioInputField
                        fieldName={"annualReport.informationChange.principalAddress.changed"}
                        fieldLabel={"Principal Address?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                        onChange={(e: any) => {
                            if (e.target.value === "no") {
                                if (selectedProducts["principalAddressChanges"]) {
                                    updateShoppingCart("principalAddressChanges");
                                }
                            } else {
                                if (!selectedProducts["principalAddressChanges"]) {
                                    updateShoppingCart("principalAddressChanges");
                                }
                            }
                            setFieldValue("annualReport.informationChange.principalAddress.changed", e.target.value);
                        }}
                    />
                    {values.annualReport.informationChange.principalAddress.changed === "yes" && (
                        <>
                            <ImportantMessage>
                                <b>IMPORTANT NOTICE:</b>{" "}<u>Changing your principle address requires an additional $25 fee and has been added to your total cost.</u>
                            </ImportantMessage>

                            <AddressInputFieldGroup
                                fieldName={
                                    "annualReport.informationChange.principalAddress.newAddress"
                                }
                                errors={errors}
                                touched={touched}
                                fieldMessages={{
                                    state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``
                                }}
                                disableFields={{
                                    state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
                                }}
                            />
                        </>
                    )}


                    <RadioInputField
                        fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
                        fieldLabel={"Registered Agent?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                        onChange={(e: any) => {
                            if (e.target.value === "no") {
                                if (selectedProducts["registeredAgentChanges"]) {
                                    updateShoppingCart("registeredAgentChanges");
                                }
                            } else {
                                if (!selectedProducts["registeredAgentChanges"]) {
                                    updateShoppingCart("registeredAgentChanges");
                                }
                            }
                            setFieldValue("annualReport.informationChange.registeredAgent.agentChange", e.target.value);
                        }}
                    />
                    {values.annualReport.informationChange.registeredAgent.agentChange === "yes" && (
                        <>
                            <ImportantMessage>
                                <b>IMPORTANT NOTICE:</b>{" "}<u>Changing your registered agent requires an additional $25 fee and has been added to your total cost.</u>
                            </ImportantMessage>

                            <RadioInputField
                                fieldName={"annualReport.informationChange.registeredAgent.agentType"}
                                fieldLabel={"Is the new registered agent an individual or an organization?"}
                                errors={errors}
                                required={true}
                                touched={touched}
                                options={[
                                    { value: "individual", label: "Individual" },
                                    { value: "organization", label: "Organization" },
                                ]}
                            />

                            {values.annualReport.informationChange.registeredAgent.agentType === "individual" && (
                                <>
                                    <SelectFieldInput
                                        fieldName={"annualReport.informationChange.registeredAgent.raCapacity"}
                                        fieldLabel={"Registered Agent Capacity"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"- Select Capacity -"}
                                        options={[
                                            { value: "Director of the Corporation", label: "Director of the Corporation" },
                                            { value: "Member of the Virginia State Bar", label: "Member of the Virginia State Bar" },
                                            { value: "Officer of the Corporation", label: "Officer of the Corporation" },
                                        ]}
                                    />
                                    <TextInputField
                                        fieldName={"annualReport.informationChange.registeredAgent.firstName"}
                                        fieldLabel={"First Name"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"First Name"}
                                    />
                                    <TextInputField
                                        fieldName={"annualReport.informationChange.registeredAgent.lastName"}
                                        fieldLabel={"Last Name"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"Last Name"}
                                    />
                                </>
                            )}

                            {values.annualReport.informationChange.registeredAgent.agentType === "organization" && (
                                <>
                                    <TextInputField
                                        fieldName={"annualReport.informationChange.registeredAgent.businessName"}
                                        fieldLabel={"Business Name"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"Business Name"}
                                    />
                                </>
                            )}

                            {(values.annualReport.informationChange.registeredAgent.agentType === "organization" ||
                                values.annualReport.informationChange.registeredAgent.agentType === "individual") && (
                                    <AddressInputFieldGroup
                                        fieldName={"annualReport.informationChange.registeredAgent.newAddress"}
                                        errors={errors}
                                        touched={touched}
                                        fieldMessages={{
                                            state: forcedRegistered[sitePhrasing.state] ? `Your registered agent must be located in ${sitePhrasing.state}.` : ``,
                                        }}
                                        disableFields={{
                                            state: forcedRegistered[sitePhrasing.state] ? true : false,
                                        }}
                                    />
                                )}
                        </>
                    )}


                </>
            )}

            {(values.annualReport.typeOfEntity === "stockCorporation" || values.annualReport.typeOfEntity === "nonStockCorporation") && (
                <>
                    <RadioInputField
                        fieldName={
                            "annualReport.informationChange.emailAddress.emailAddressChange"
                        }
                        fieldLabel={"Principal Business E-Mail address?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.annualReport.informationChange.emailAddress.emailAddressChange === "yes" && (
                        <TextInputField
                            fieldName={
                                "annualReport.informationChange.emailAddress.newEmailAddress"
                            }
                            fieldLabel={"E-mail address"}
                            errors={errors}
                            touched={touched}
                            required={true}
                            placeholder={"Enter yournew COMPLETE E-mail address"}
                        />
                    )}

                    {/* change contactNumber.changeNumber */}
                    <RadioInputField
                        fieldName={
                            "annualReport.informationChange.contactNumber.changeNumber"
                        }
                        fieldLabel={"Principal Business Contact Number?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.annualReport.informationChange.contactNumber.changeNumber === "yes" && (
                        <>
                            <TextInputField
                                mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                fieldName={'annualReport.informationChange.contactNumber.newContactNumber'}
                                fieldLabel={'New Contact Number'}
                                errors={errors}
                                touched={touched}
                                required={true}
                                placeholder={'New Contact Number'}
                            />
                        </>
                    )}


                    <RadioInputField
                        fieldName={"annualReport.informationChange.principalAddress.changed"}
                        fieldLabel={"Principal Address?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    // onChange={(e: any) => {
                    //     if (e.target.value === "no") {
                    //         if (selectedProducts["principalAddressChanges"]) {
                    //             updateShoppingCart("principalAddressChanges");
                    //         }
                    //     } else {
                    //         if (!selectedProducts["principalAddressChanges"]) {
                    //             updateShoppingCart("principalAddressChanges");
                    //         }
                    //     }
                    //     setFieldValue("annualReport.informationChange.principalAddress.changed", e.target.value);
                    // }}
                    />
                    {values.annualReport.informationChange.principalAddress.changed === "yes" && (
                        <>
                            {/* <ImportantMessage>
                                <b>IMPORTANT NOTICE:</b>{" "}<u>Changing your principle address requires an additional $25 fee and has been added to your total cost.</u>
                            </ImportantMessage> */}

                            <AddressInputFieldGroup
                                fieldName={
                                    "annualReport.informationChange.principalAddress.newAddress"
                                }
                                errors={errors}
                                touched={touched}
                                fieldMessages={{
                                    state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``
                                }}
                                disableFields={{
                                    state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
                                }}
                            />
                        </>
                    )}

                    {/* ENTITY OFFICER FIELD SET */}
                    <RadioInputField
                        fieldName={"annualReport.informationChange.officer.officerChange"}
                        fieldLabel={"Entity Officers?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                    />
                    {values.annualReport.informationChange.officer.officerChange === "yes" && (
                        <>

                            <RadioInputField
                                fieldName={"annualReport.informationChange.officer.removingOfficers"}
                                fieldLabel={`Are you removing any Entity Officers?`}
                                errors={errors}
                                required={true}
                                touched={touched}
                                options={yesOrNoValues}
                            />
                            {values.annualReport.informationChange.officer.removingOfficers === "yes" && (
                                <>
                                    <ArrayFieldInput
                                        fieldName={"annualReport.informationChange.officer.removedOfficers"}
                                        values={values.annualReport.informationChange.officer.removedOfficers}
                                        blankEntryFn={() => blankOfficerFn(values.annualReport.typeOfEntity)}
                                        fields={officerFields(values.annualReport.typeOfEntity)}
                                        touched={touched}
                                        errors={errors}
                                        removeText={"- Click to remove entry"}
                                        addText={"+ Click to add an entry"}
                                        setFieldValue={setFieldValue}
                                    />
                                </>
                            )}

                            <RadioInputField
                                fieldName={"annualReport.informationChange.officer.addingOfficers"}
                                fieldLabel={`Are you adding any Entity Officers?`}
                                errors={errors}
                                required={true}
                                touched={touched}
                                options={yesOrNoValues}
                            />
                            {values.annualReport.informationChange.officer.addingOfficers === "yes" && (
                                <ArrayFieldInput
                                    fieldName={"annualReport.informationChange.officer.addedOfficers"}
                                    values={values.annualReport.informationChange.officer.addedOfficers}
                                    blankEntryFn={() => blankOfficerFn(values.annualReport.typeOfEntity)}
                                    fields={officerFields(values.annualReport.typeOfEntity)}
                                    touched={touched}
                                    errors={errors}
                                    removeText={"- Click to remove entry"}
                                    addText={"+ Click to add an entry"}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </>
                    )}

                    <RadioInputField
                        fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
                        fieldLabel={"Registered Agent?"}
                        errors={errors}
                        required={true}
                        touched={touched}
                        options={yesOrNoValues}
                        onChange={(e: any) => {
                            if (e.target.value === "no") {
                                if (selectedProducts["registeredAgentChanges"]) {
                                    updateShoppingCart("registeredAgentChanges");
                                }
                            } else {
                                if (!selectedProducts["registeredAgentChanges"]) {
                                    updateShoppingCart("registeredAgentChanges");
                                }
                            }
                            setFieldValue("annualReport.informationChange.registeredAgent.agentChange", e.target.value);
                        }}
                    />
                    {values.annualReport.informationChange.registeredAgent.agentChange === "yes" && (
                        <>
                            <ImportantMessage>
                                <b>IMPORTANT NOTICE:</b>{" "}<u>Changing your registered agent requires an additional $25 fee and has been added to your total cost.</u>
                            </ImportantMessage>

                            <RadioInputField
                                fieldName={"annualReport.informationChange.registeredAgent.agentType"}
                                fieldLabel={"Is the new registered agent an individual or an organization?"}
                                errors={errors}
                                required={true}
                                touched={touched}
                                options={[
                                    { value: "individual", label: "Individual" },
                                    { value: "organization", label: "Organization" },
                                ]}
                            />

                            {values.annualReport.informationChange.registeredAgent.agentType === "individual" && (
                                <>
                                    <SelectFieldInput
                                        fieldName={"annualReport.informationChange.registeredAgent.raCapacity"}
                                        fieldLabel={"Registered Agent Capacity"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"- Select Capacity -"}
                                        options={[
                                            { value: "Director of the Corporation", label: "Director of the Corporation" },
                                            { value: "Member of the Virginia State Bar", label: "Member of the Virginia State Bar" },
                                            { value: "Officer of the Corporation", label: "Officer of the Corporation" },
                                        ]}
                                    />
                                    <TextInputField
                                        fieldName={"annualReport.informationChange.registeredAgent.firstName"}
                                        fieldLabel={"First Name"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"First Name"}
                                    />
                                    <TextInputField
                                        fieldName={"annualReport.informationChange.registeredAgent.lastName"}
                                        fieldLabel={"Last Name"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"Last Name"}
                                    />
                                </>
                            )}

                            {values.annualReport.informationChange.registeredAgent.agentType === "organization" && (
                                <>
                                    <TextInputField
                                        fieldName={"annualReport.informationChange.registeredAgent.agentEntityName"}
                                        fieldLabel={"Agent Entity Name"}
                                        errors={errors}
                                        touched={touched}
                                        required={true}
                                        placeholder={"Agent Entity Name"}
                                    />
                                </>
                            )}

                            {(values.annualReport.informationChange.registeredAgent.agentType === "individual") && (
                                    <AddressInputFieldGroup
                                        fieldName={"annualReport.informationChange.registeredAgent.newAddress"}
                                        errors={errors}
                                        touched={touched}
                                        fieldMessages={{
                                            state: forcedRegistered[sitePhrasing.state] ? `Your registered agent must be located in ${sitePhrasing.state}.` : ``,
                                        }}
                                        disableFields={{
                                            state: forcedRegistered[sitePhrasing.state] ? true : false,
                                        }}
                                    />
                                )}
                        </>
                    )}

                </>
            )}


            <CheckboxInputField
                fieldName={'annualReport.annualAgree'}
                fieldLabel={`With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Virginia Business Filing Center as a "Third Party Designee" to renew my business on my behalf using the information I provided.`}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={'annualReport.tellingTruth'}
                fieldLabel={`By checking the box below, you certify the following:`}
                touched={touched}
                errors={errors}
                customLabel={() => (
                    <>
                        I affirm that the information contained in this electronic submission is accurate and complete and that I am legally authorized to sign the document. I acknowledges that it is unlawful to sign a document that is false in any material respect with the intent that it be delivered to the Commission for filing. I affirm that the information contained in this report is accurate and complete as of the date of this filing and that the person signing is authorized to sign the annual report. 
                    </>
                )}
            />
            <CheckboxInputField
                fieldName={"iAgree"}
                fieldLabel={iAgreeText}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={"termAndConditionAgree"}
                fieldLabel={<span dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }} />}
                touched={touched}
                errors={errors}
            />

            <TextInputField
                fieldName={"annualReport.electronicSignature"}
                fieldLabel={"Name of Individual Signing - Electronic Signature"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Electronic Signature"}
                customClass={"signature-font"}
            />

            <TextInputField
                fieldName={"annualReport.title"}
                fieldLabel={"Title"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Title"}
            />

            <TextInputField
                fieldName={"annualReport.firstName"}
                fieldLabel={"First Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"First Name"}
            />

            <TextInputField
                fieldName={"annualReport.lastName"}
                fieldLabel={"Last Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Last Name"}
            />

        </>
    );
}


export const blankOfficerFn = (typeOfCorp: string) => ({
    name: "",
    director: "",
    titles: [],
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
});

export const officerFields = (typeOfCorp: string): ArrayEntryFieldType[] => {

    return [
        {
            fieldType: "text",
            fieldName: "name",
            fieldLabel: "Name",
            placeholder: "Name",
            required: true,
        },

        {
            fieldType: "radio",
            fieldName: "director",
            fieldLabel: "Director",
            required: true,
            options: [
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
            ],
        },

        {
            fieldType: "checkboxArray",
            fieldName: "titles",
            fieldLabel: "Titles",
            placeholder: "Titles",
            required: true,
            options: [
                { value: "President", label: "President" },
                { value: "Vice President", label: "Vice President" },
                { value: "Secretary", label: "Secretary" },
                { value: "Treasurer", label: "Treasurer" },
                { value: "Officer", label: "Officer" },
                { value: "Other", label: "Other" },
            ]
        },
        {
            fieldType: "text",
            fieldName: "address",
            fieldLabel: "Street",
            placeholder: "Street",
            required: true,
        },
        {
            fieldType: "text",
            fieldName: "address2",
            fieldLabel: "Apt #",
            placeholder: "Apt #",
        },
        {
            fieldType: "text",
            fieldName: "city",
            fieldLabel: "City",
            placeholder: "City",
            required: true,
        },
        {
            fieldType: "text",
            fieldName: "state",
            fieldLabel: "State",
            placeholder: "State",
            required: true,
        },
        {
            fieldType: "text",
            fieldName: "zip",
            fieldLabel: "Zip",
            placeholder: "Zip",
            required: true,
        },
    ]
};