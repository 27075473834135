import { ErrorMessage, Field } from "formik";
import { ErrorComponent } from "../../Error/ErrorComponent";
import { checkVal } from "../helperFunctions";
import MaskedInput from "react-text-mask";
import { ReactNode } from "react";

export default function TextInputField(props: {
  fieldLabel: string;
  fieldSubLabel?: string;
  customFieldSubLabel?: () => ReactNode;
  customFieldInstructions?: () => ReactNode;
  fieldName: string;
  required?: boolean;
  placeholder?: string;
  touched: any;
  errors: any;
  customClass?: string;
  fieldSubLabelCustomClass?: string;
  fieldType?: string;
  mask?: any[];
  disabled?: boolean;
  extra?: any;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}) {
  const {
    fieldLabel,
    fieldSubLabel,
    customFieldSubLabel,
    customFieldInstructions,
    fieldName,
    required,
    placeholder,
    touched,
    errors,
    customClass,
    fieldSubLabelCustomClass,
    fieldType,
    mask,
    onChange,
    disabled,
    extra,
    onKeyDown,
  } = props;

  const hasError = checkVal(errors, fieldName);
  const hasTouched = checkVal(touched, fieldName);
  const fieldClass = `${hasTouched
    ? !hasError
      ? "valid"
      : "error"
    : hasError
      ? "silent-error"
      : ""
    }`;

  if (mask) {
    return (
      <div className={`field-container ${customClass ? customClass : ""}`}>
        <div className="label-input">
          <label htmlFor={fieldName}>
            {fieldLabel}
            {required ? "*" : (<>&nbsp;</>)}
          </label>
          <br />
          {fieldSubLabel && (
            <>
              <div className={`sub-label ${fieldSubLabelCustomClass ? fieldSubLabelCustomClass : ""}`}>
                {fieldSubLabel}
              </div>
            </>
          )}
          {customFieldSubLabel && (
            <>
              <div className={`sub-label ${fieldSubLabelCustomClass ? fieldSubLabelCustomClass : ""}`}>
                {customFieldSubLabel()}
              </div>
            </>
          )}
        </div>

        <div className="field-input">
          <Field
            className={extra?.unstyled ? '' : fieldClass}
            required={required}
            type={fieldType ? fieldType : "text"}
            name={fieldName}
            disabled={disabled}
            placeholder={placeholder ? placeholder : ""}
            {...(onChange ? { onChange } : {})}
            {...(onKeyDown ? { onKeyDown } : {})}
            render={({ field }: any) => (
              <MaskedInput
                mask={mask}
                className={fieldClass}
                {...field}
                placeholder={placeholder ? placeholder : ""}
              />
            )}
          />
          <ErrorMessage name={fieldName} render={ErrorComponent} />
        </div>
        {customFieldInstructions && (
          <div className="sub-label field-instructions">
            {customFieldInstructions()}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`field-container ${customClass ? customClass : ""}`}>
      <div className="label-input">
        <label htmlFor={fieldName}>
          {fieldLabel}
          {required ? "*" : ""}
        </label>
        <br />
        {fieldSubLabel && (
          <div className={`sub-label ${fieldSubLabelCustomClass ? fieldSubLabelCustomClass : ""}`}>
            {fieldSubLabel}
          </div>
        )}
        {customFieldSubLabel && (
          <>
            <div className={`sub-label ${fieldSubLabelCustomClass ? fieldSubLabelCustomClass : ""}`}>
              {customFieldSubLabel()}
            </div>
          </>
        )}
      </div>
      <div className="field-input">
        <Field
          className={extra?.unstyled ? '' : fieldClass}
          required={required}
          type={fieldType ? fieldType : "text"}
          name={fieldName}
          disabled={disabled}
          {...(onChange ? { onChange } : {})}
          {...(onKeyDown ? { onKeyDown } : {})}
          // {...extra}
          placeholder={placeholder ? placeholder : ""}
        />
        <ErrorMessage name={fieldName} render={ErrorComponent} />
      </div>
      {customFieldInstructions && (
        <div className="sub-label field-instructions">
          {customFieldInstructions()}
        </div>
      )}
    </div>
  );
}
