import { sitePhrasing } from "../../../../Language/languageHandler";
import { updateProductPrice } from "../../../../Redux/Shopping/shoppingHelpers";
import {
  updateCart,
  updateProductCartInfo,
} from "../../../../Redux/Shopping/shoppingSlice";
import { useAppDispatch } from "../../../../Redux/store";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import {
  ArrayEntryFieldType,
  ArrayFieldInput,
} from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import ImportantMessage from "../../../ImportantMessage/ImportantMessage";
import { iAgreeText, termAndConditionAcceptText, yesOrNoValues } from "../PurchaseConstants";
import { forcedRegistered } from "../PurchaseFormHelpers";

export default function TennesseeAnnualReportFields(props: {
  errors: any;
  touched: any;
  values: any;
  selectedProducts: any;
  setFieldValue: any;
}) {
  const { errors, touched, values, selectedProducts, setFieldValue } = props;

  const dispatch = useAppDispatch();

  const updateShoppingCart = (product: string) => {
    dispatch(updateCart(product));
  };

  const updateCartInfo = (product: string) => {
    dispatch(updateProductCartInfo(product));
  };

  return (
    <>

      <RadioInputField
        fieldName={"annualReport.typeOfEntity"}
        fieldLabel={"Is your company an LLC or CORP?"}
        errors={errors}
        required={true}
        touched={touched}
        options={[
          { value: "LLC", label: "LLC" },
          { value: "CORP", label: "CORP" },
        ]}
        onChange={(e: any) => {

          if (e.target.value === "CORP") {
            setFieldValue("annualReport.informationChange.director.directorChange", "");
            setFieldValue("annualReport.informationChange.llcManager.llcManagerChange", "no");
            setFieldValue("annualReport.membersWithStake", 1);
          }

          if (e.target.value === "LLC") {
            setFieldValue("annualReport.informationChange.llcManager.llcManagerChange", "");
            setFieldValue("annualReport.informationChange.director.directorChange", "no");
            setFieldValue("annualReport.membersWithStake", "");
            setFieldValue("annualReport.isPersonSigningPresident", "");
            setFieldValue("annualReport.isPersonSigningSecretary", "");
          }

          if (selectedProducts["additionalMembers"]) {
            updateShoppingCart("additionalMembers");
          }

          // reset officers fields
          setFieldValue("annualReport.informationChange.officer.officerChange", "");
          setFieldValue("annualReport.informationChange.officer.removingOfficers", "");
          setFieldValue("annualReport.informationChange.officer.addingOfficers", "");
          setFieldValue("annualReport.informationChange.officer.removedOfficers", []);
          setFieldValue("annualReport.informationChange.officer.addedOfficers", []);

          setFieldValue("annualReport.typeOfEntity", e.target.value);
        }}
      />

      <TextInputField
        fieldName={"annualReport.natureOfBusiness"}
        fieldLabel={"Briefly describe the nature of the business"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Briefly describe the nature of the business"}
      />

      {/* <RadioInputField
        fieldName={"annualReport.informationChange.NAICS.changeCodes"}
        fieldLabel={"Nature of Business (NAICS codes)?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      /> */}

      {/* {values.annualReport.informationChange.NAICS.changeCodes === "yes" && ( */}

      <div className="field-container">
        <label htmlFor={'annualReport.informationChange.NAICS.changeCodes'}>Nature of Business (NAICS codes)*</label>
      </div>
      <div className="naics-section">
        (Enter up to 3 NAICS codes)
        <div className="naics-split-box">
          <TextInputField
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            fieldName={"annualReport.informationChange.NAICS.code1"}
            fieldLabel={"1."}
            errors={errors}
            touched={touched}
            required={true}
            customClass="inline-input border-input"
            placeholder={"NAICS Code"}
          />
          <TextInputField
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            fieldName={"annualReport.informationChange.NAICS.code2"}
            fieldLabel={"2."}
            errors={errors}
            touched={touched}
            required={false}
            customClass="inline-input border-input"
            placeholder={"NAICS Code"}
          />
          <TextInputField
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            fieldName={"annualReport.informationChange.NAICS.code3"}
            fieldLabel={"3."}
            errors={errors}
            touched={touched}
            required={false}
            customClass="inline-input border-input"
            placeholder={"NAICS Code"}
          />
        </div>
        <a href="https://www.naics.com/search/" target="_blank" className="link-text">Click Here to search for NAICS codes</a>
      </div>
      {/* )} */}

      {values.annualReport.typeOfEntity === "CORP" && (
        <>
          <RadioInputField
            fieldName={"annualReport.isPersonSigningPresident"}
            fieldLabel={"Is the person signing this application President of this company?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.isPersonSigningPresident === "no" && (
            <>
              <TextInputField
                fieldName={"annualReport.entityPresident"}
                fieldLabel={"Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Name"}
              />
              <AddressInputFieldGroup
                fieldName={"annualReport.entityPresidentAddress"}
                errors={errors}
                touched={touched}
              />
            </>
          )}

          <RadioInputField
            fieldName={"annualReport.isPersonSigningSecretary"}
            fieldLabel={"Is the person signing this application Secretary of this company?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />

          {values.annualReport.isPersonSigningSecretary === "no" && (
            <>
              <TextInputField
                fieldName={"annualReport.entitySecretary"}
                fieldLabel={"Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Name"}
              />
              <AddressInputFieldGroup
                fieldName={"annualReport.entitySecretaryAddress"}
                errors={errors}
                touched={touched}
              />
            </>
          )}

        </>
      )}


      <h3>Do you need to change any of the following information?</h3>
      <RadioInputField
        fieldName={"annualReport.informationChange.principalAddress.changed"}
        fieldLabel={"Principal Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.principalAddress.changed === "yes" && (
        <AddressInputFieldGroup
          fieldName={"annualReport.informationChange.principalAddress.newAddress"}
          errors={errors}
          touched={touched}
          showCounty={true}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.mailingAddress.changed"}
        fieldLabel={"Mailing Address?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
      />
      {values.annualReport.informationChange.mailingAddress.changed === "yes" && (
        <AddressInputFieldGroup
          fieldName={"annualReport.informationChange.mailingAddress.newAddress"}
          errors={errors}
          touched={touched}
          showCounty={true}
        />
      )}

      <RadioInputField
        fieldName={"annualReport.informationChange.registeredAgent.agentChange"}
        fieldLabel={"Registered Agent?"}
        errors={errors}
        required={true}
        touched={touched}
        options={yesOrNoValues}
        onChange={(e: any) => {
          if (e.target.value === "no") {
            if (selectedProducts["registeredAgentChanges"]) {
              updateShoppingCart("registeredAgentChanges");
            }
          } else {
            if (!selectedProducts["registeredAgentChanges"]) {
              updateShoppingCart("registeredAgentChanges");
            }
          }
          setFieldValue("annualReport.informationChange.registeredAgent.agentChange", e.target.value);
          setFieldValue("annualReport.informationChange.registeredAgent.agentType", "");
        }}
      />
      {values.annualReport.informationChange.registeredAgent.agentChange === "yes" && (
        <>
          <ImportantMessage>
            <b>IMPORTANT NOTICE:</b>{" "}<u>Changing your registered agent requires an additional $20 fee and has been added to your total cost.</u>
          </ImportantMessage>

          <RadioInputField
            fieldName={"annualReport.informationChange.registeredAgent.agentType"}
            fieldLabel={"Is the new registered agent an individual or an organization?"}
            errors={errors}
            required={true}
            touched={touched}
            options={[
              { value: "individual", label: "Individual" },
              { value: "organization", label: "Organization" },
            ]}
          />

          {values.annualReport.informationChange.registeredAgent.agentType === "individual" && (
            <>
              <TextInputField
                fieldName={"annualReport.informationChange.registeredAgent.firstName"}
                fieldLabel={"First Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"First Name"}
              />
              <TextInputField
                fieldName={"annualReport.informationChange.registeredAgent.lastName"}
                fieldLabel={"Last Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Last Name"}
              />
            </>
          )}

          {values.annualReport.informationChange.registeredAgent.agentType === "organization" && (
            <>
              <TextInputField
                fieldName={"annualReport.informationChange.registeredAgent.businessName"}
                fieldLabel={"Business Name"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"Business Name"}
              />
            </>
          )}

          {(values.annualReport.informationChange.registeredAgent.agentType === "organization" ||
            values.annualReport.informationChange.registeredAgent.agentType === "individual") && (
              <>
                <TextInputField
                  fieldName={"annualReport.informationChange.registeredAgent.email"}
                  fieldLabel={"E-mail Address"}
                  errors={errors}
                  touched={touched}
                  required={true}
                  placeholder={"E-mail Address"}
                />

                <AddressInputFieldGroup
                  fieldName={"annualReport.informationChange.registeredAgent.newAddress"}
                  errors={errors}
                  touched={touched}
                  fieldMessages={{
                    state: forcedRegistered[sitePhrasing.state] ? `Your registered agent must be located in ${sitePhrasing.state}.` : ``,
                  }}
                  disableFields={{
                    state: forcedRegistered[sitePhrasing.state] ? true : false,
                  }}
                  showCounty={true}
                />
              </>
            )}
        </>
      )}


      {/* {values.annualReport.typeOfEntity === "LLC" && (
        <>
          <RadioInputField
            fieldName={"annualReport.informationChange.llcManager.llcManagerChange"}
            fieldLabel={"LLC Managers?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.llcManager.llcManagerChange === "yes" && (
            <>
              <RadioInputField
                fieldName={"annualReport.informationChange.llcManager.removingAgents"}
                fieldLabel={`Are you removing any LLC Managers?`}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.annualReport.informationChange.llcManager.removingAgents === "yes" && (
                <ArrayFieldInput
                  fieldName={"annualReport.informationChange.llcManager.removedAgents"}
                  values={values.annualReport.informationChange.llcManager.removedAgents}
                  blankEntryFn={blankLlcManagerFn}
                  fields={llcManagerFields}
                  touched={touched}
                  errors={errors}
                  removeText={"- Click to remove entry"}
                  addText={"+ Click to add an entry"}
                />
              )}

              <RadioInputField
                fieldName={"annualReport.informationChange.llcManager.addingAgents"}
                fieldLabel={`Are you adding any LLC Managers?`}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.annualReport.informationChange.llcManager.addingAgents === "yes" && (
                <ArrayFieldInput
                  fieldName={"annualReport.informationChange.llcManager.addedAgents"}
                  values={values.annualReport.informationChange.llcManager.addedAgents}
                  blankEntryFn={blankLlcManagerFn}
                  fields={llcManagerFields}
                  touched={touched}
                  errors={errors}
                  removeText={"- Click to remove entry"}
                  addText={"+ Click to add an entry"}
                />
              )}
            </>
          )}
        </>
      )} */}

      {values.annualReport.typeOfEntity !== "" && (
        <>
          <RadioInputField
            fieldName={"annualReport.informationChange.officer.officerChange"}
            fieldLabel={"Officers?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.officer.officerChange === "yes" && (
            <>

              {values.annualReport.typeOfEntity === "CORP" && (
                <ImportantMessage
                  extraStyles={{
                    color: 'black',
                    border: '4px solid red',
                    textAlign: 'center',
                  }}
                >
                  If removing a President and/or Secretary, a new entry with that title is required.
                </ImportantMessage>
              )}

              <RadioInputField
                fieldName={"annualReport.informationChange.officer.removingOfficers"}
                fieldLabel={`Are you removing any Officers?`}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.annualReport.informationChange.officer.removingOfficers === "yes" && (
                <>
                  <ArrayFieldInput
                    fieldName={"annualReport.informationChange.officer.removedOfficers"}
                    values={values.annualReport.informationChange.officer.removedOfficers}
                    blankEntryFn={() => blankOfficerFn(values.annualReport.typeOfEntity)}
                    fields={officerFields(values.annualReport.typeOfEntity)}
                    touched={touched}
                    errors={errors}
                    removeText={"- Click to remove entry"}
                    addText={"+ Click to add an entry"}
                  />
                </>
              )}

              <RadioInputField
                fieldName={"annualReport.informationChange.officer.addingOfficers"}
                fieldLabel={`Are you adding any Officers?`}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.annualReport.informationChange.officer.addingOfficers === "yes" && (
                <ArrayFieldInput
                  fieldName={"annualReport.informationChange.officer.addedOfficers"}
                  values={values.annualReport.informationChange.officer.addedOfficers}
                  blankEntryFn={() => blankOfficerFn(values.annualReport.typeOfEntity)}
                  fields={officerFields(values.annualReport.typeOfEntity)}
                  touched={touched}
                  errors={errors}
                  removeText={"- Click to remove entry"}
                  addText={"+ Click to add an entry"}
                />
              )}
            </>
          )}
        </>
      )}

      {values.annualReport.typeOfEntity === "LLC" && (
        <>
          <TextInputField
            fieldName={"annualReport.membersWithStake"}
            fieldLabel={"Number of members who manage the business"}
            errors={errors}
            touched={touched}
            required={true}
            fieldType={"number"}
            placeholder={"Number of members who manage the business"}
            onChange={(e: any) => {
              if (e.target.value > 6) {
                const price = Math.min((e.target.value - 6) * 5000, 300000);
                updateProductPrice("additionalMembers", price);
                updateCartInfo("additionalMembers");
              } else {
                if (selectedProducts["additionalMembers"]) {
                  updateShoppingCart("additionalMembers");
                }
              }
              setFieldValue("annualReport.membersWithStake", e.target.value);
            }}
          />
          {values.annualReport.membersWithStake > 6 && (
            <ImportantMessage>
              <b>IMPORTANT NOTICE:</b> There is no additional charge for businesses with 6 or less members.&nbsp;
              <u>
                Any business which has 7 or more members is required to pay an additional $50 per additional member after 6, at a maximum of $3000.&nbsp;
                <b>
                  The accumulated fee based on your entry has been added to your total cost.
                </b>
              </u>
            </ImportantMessage>
          )}
        </>
      )}


      {/* {values.annualReport.typeOfEntity === "CORP" && (
        <>
          <RadioInputField
            fieldName={"annualReport.informationChange.director.directorChange"}
            fieldLabel={"Directors?"}
            errors={errors}
            required={true}
            touched={touched}
            options={yesOrNoValues}
          />
          {values.annualReport.informationChange.director.directorChange === "yes" && (
            <>
              <RadioInputField
                fieldName={"annualReport.informationChange.director.removingDirectors"}
                fieldLabel={`Are you removing any Directors?`}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.annualReport.informationChange.director.removingDirectors === "yes" && (
                <ArrayFieldInput
                  fieldName={"annualReport.informationChange.director.removedDirectors"}
                  values={values.annualReport.informationChange.director.removedDirectors}
                  blankEntryFn={blankDirectorFn}
                  fields={directorFields}
                  touched={touched}
                  errors={errors}
                  removeText={"- Click to remove entry"}
                  addText={"+ Click to add an entry"}
                />
              )}

              <RadioInputField
                fieldName={"annualReport.informationChange.director.addingDirectors"}
                fieldLabel={`Are you adding any Directors?`}
                errors={errors}
                required={true}
                touched={touched}
                options={yesOrNoValues}
              />
              {values.annualReport.informationChange.director.addingDirectors === "yes" && (
                <ArrayFieldInput
                  fieldName={"annualReport.informationChange.director.addedDirectors"}
                  values={values.annualReport.informationChange.director.addedDirectors}
                  blankEntryFn={blankDirectorFn}
                  fields={directorFields}
                  touched={touched}
                  errors={errors}
                  removeText={"- Click to remove entry"}
                  addText={"+ Click to add an entry"}
                />
              )}
            </>
          )}
        </>
      )} */}

      <CheckboxInputField
        fieldName={'annualReport.annualAgree'}
        fieldLabel={`With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Tennessee Business Filing Center as a "Third Party Designee" to renew my business on my behalf using the information I provided.`}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={'annualReport.tellingTruth'}
        fieldLabel={`By checking the box below, you certify the following:`}
        touched={touched}
        errors={errors}
        customLabel={() => (
          <>
            By checking the box below, you certify the following: <br />
            <ul style={{ margin: '10px 0' }}>
              <li>
                I understand that the information submitted electronically by me will be used to generate an annual report for this company that will be filed with the Secretary of State;
              </li>
              <li>
                I am authorized to submit an annual report for this company;
              </li>
              <li>
                I intend and agree that the electronic submission of the information set forth herein constitutes my signature of the annual report;
              </li>
              <li>
                The information is correct; and
              </li>
              <li>
                I understand that the submission of fraudulent information may subject the company to administrative dissolution/revocation proceedings and may also subject me to criminal prosecution.
              </li>
            </ul>
          </>
        )}
      />
      <CheckboxInputField
        fieldName={"iAgree"}
        fieldLabel={iAgreeText}
        touched={touched}
        errors={errors}
      />

      <CheckboxInputField
        fieldName={"termAndConditionAgree"}
        fieldLabel={<span dangerouslySetInnerHTML={{ __html: termAndConditionAcceptText }} />}
        touched={touched}
        errors={errors}
      />
      {/* <CheckboxInputField
        fieldName={'annualReport.iAmAuthorized'}
        fieldLabel={'The individual "signing" this document affirms that the facts stated herein are true. This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual, otherwise it constitutes forgery.'}
        touched={touched}
        errors={errors}
      /> */}

      <TextInputField
        fieldName={"annualReport.electronicSignature"}
        fieldLabel={"Client Agreement & Signature"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Electronic Signature"}
        customClass={"signature-font"}
      />

      <TextInputField
        fieldName={"firstName"}
        fieldLabel={"First Name"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"First Name"}
      />

      <TextInputField
        fieldName={"lastName"}
        fieldLabel={"Last Name"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Last Name"}
      />

      {/* <AddressInputFieldGroup
        fieldName={"generalAddress"}
        errors={errors}
        touched={touched}
        fieldLabels={{
          address: "Address 1",
          address2: "Address 2",
          city: "City",
          state: "State",
          zip: "Zip",
        }}
      /> */}

      <TextInputField
        fieldName={"annualReport.title"}
        fieldLabel={"Title"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Title"}
      />

      <TextInputField
        mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/,]}
        fieldName={"phoneNumber"}
        fieldLabel={"Phone Number"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Phone Number"}
      />

      <TextInputField
        fieldName={"email"}
        fieldLabel={"Email"}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={"Email"}
      />
    </>
  );
}

export const blankLlcManagerFn = () => ({
  name: "",
  title: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
});

export const blankOfficerFn = (typeOfCorp: string) => ({
  name: "",
  title: typeOfCorp === "CORP" ? "" : "-",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
});

export const blankDirectorFn = () => ({
  name: "",
  title: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
});

export const llcManagerFields: ArrayEntryFieldType[] = [
  {
    fieldType: "text",
    fieldName: "name",
    fieldLabel: "Name",
    placeholder: "Name",
    required: true,
  },
  // {
  //   fieldType: 'text',
  //   fieldName: 'title',
  //   fieldLabel: 'Title',
  //   placeholder: 'Title',
  //   required: true,
  // },
  {
    fieldType: "text",
    fieldName: "address",
    fieldLabel: "Street",
    placeholder: "Street",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "address2",
    fieldLabel: "Apt #",
    placeholder: "Apt #",
  },
  {
    fieldType: "text",
    fieldName: "city",
    fieldLabel: "City",
    placeholder: "City",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "state",
    fieldLabel: "State",
    placeholder: "State",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "zip",
    fieldLabel: "Zip",
    placeholder: "Zip",
    required: true,
  },
];

export const directorFields: ArrayEntryFieldType[] = [
  {
    fieldType: "text",
    fieldName: "name",
    fieldLabel: "Name",
    placeholder: "Name",
    required: true,
  },
  // {
  //   fieldType: 'text',
  //   fieldName: 'title',
  //   fieldLabel: 'Title',
  //   placeholder: 'Title',
  //   required: true,
  // },
  {
    fieldType: "text",
    fieldName: "address",
    fieldLabel: "Street",
    placeholder: "Street",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "address2",
    fieldLabel: "Apt #",
    placeholder: "Apt #",
  },
  {
    fieldType: "text",
    fieldName: "city",
    fieldLabel: "City",
    placeholder: "City",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "state",
    fieldLabel: "State",
    placeholder: "State",
    required: true,
  },
  {
    fieldType: "text",
    fieldName: "zip",
    fieldLabel: "Zip",
    placeholder: "Zip",
    required: true,
  },
];

export const officerFields = (typeOfCorp: string): ArrayEntryFieldType[] => {

  return [
    {
      fieldType: "text",
      fieldName: "name",
      fieldLabel: "Name",
      placeholder: "Name",
      required: true,
    },

    ...(typeOfCorp === "CORP" ? [
      {
        fieldType: 'text',
        fieldName: 'title',
        fieldLabel: 'Title',
        placeholder: 'Title',
        required: true,
      }
    ] as ArrayEntryFieldType[] : []),

    {
      fieldType: "text",
      fieldName: "address",
      fieldLabel: "Street",
      placeholder: "Street",
      required: true,
    },
    {
      fieldType: "text",
      fieldName: "address2",
      fieldLabel: "Apt #",
      placeholder: "Apt #",
    },
    {
      fieldType: "text",
      fieldName: "city",
      fieldLabel: "City",
      placeholder: "City",
      required: true,
    },
    {
      fieldType: "text",
      fieldName: "state",
      fieldLabel: "State",
      placeholder: "State",
      required: true,
    },
    {
      fieldType: "text",
      fieldName: "zip",
      fieldLabel: "Zip",
      placeholder: "Zip",
      required: true,
    },
  ]
};
