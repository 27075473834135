import { ErrorMessage, Field } from "formik";
import { ErrorComponent } from "../../Error/ErrorComponent";
import { checkVal } from "../helperFunctions";

export default function SelectFieldInput(props: {
  fieldLabel: string,
  fieldName: string,
  required?: boolean,
  placeholder?: string,
  touched: any,
  errors: any,
  customClass?: string,
  options: { value: string | number, label?: string }[]
  onChange?: (e: any) => void
  fieldSubLabel?: string
  disabled?: boolean
}) {

  const {
    fieldLabel,
    fieldName,
    required,
    placeholder,
    touched,
    errors,
    customClass,
    options,
    onChange,
    fieldSubLabel,
    disabled = false
  } = props

  const hasError = checkVal(errors, fieldName)
  const hasTouched = checkVal(touched, fieldName)
  const fieldClass = `${hasTouched ? !hasError ? '' : 'error' : hasError ? 'silent-error' : ''}`

  return (
    <div className={`field-container ${customClass ? customClass : ''}`}>
      <label htmlFor={fieldName}>{fieldLabel}{required ? '*' : ''}</label>
      <br />
      {fieldSubLabel && (<>
        <div className="sub-label">{fieldSubLabel}</div>
      </>)}
      <Field
        {...(onChange ? { onChange } : {})}
        className={'select-input-field ' + fieldClass}
        type="text"
        as="select"
        name={fieldName}
        disabled={disabled}
        required={required}>
        {placeholder && (
          <option value="" >{placeholder}</option>
        )}
        {options.map((option, idx) => (
          <option key={idx} value={option.value}>{option.label ? option.label : option.value}</option>
        ))}
      </Field>
      <ErrorMessage name={fieldName} render={ErrorComponent} />
    </div>
  )
}
